<template>
  <div class="custom-cookie">
    <vue-cookie-accept-decline
      :ref="'cookie'"
      :elementId="'cookie'"
      :debug="false"
      :position="'bottom-left'"
      :type="'floating'"
      :disableDecline="false"
      :transitionName="'slideFromBottom'"
      :showPostponeButton="false"
    >
      <div slot="message">
        <div class="cookie-box">
          <div
            class="is-flex js-between ai-center change-display"
            :class="
              pattern == 1 ? 'color-bg-white opacity-95' : 'color-bg-primary'
            "
          >
            <div class="text-left box-inside-cookie">
              <p class="font-22" :class="{ 'color-white': pattern == 2 }">
                นโยบายการเก็บข้อมูลคุกกี้ของผู้ใช้ (Cookie Policy)
                เพื่อประโยชน์ในการพัฒนาให้เว็บไซต์รองรับการใช้งานของท่านได้ดียิ่งขึ้น
                ทางเราจะมีการเก็บข้อมูลการใช้งานบนเว็บไซต์ (Cookie)
                โดยการเข้าชมเว็บไซต์นี้
                ถือว่าท่านตกลงยอมรับในนโยบายความเป็นส่วนตัวนี้
                <a
                  href="https://www.exim.go.th/th/privacypolicy.aspx"
                  target="_blank"
                  class="text-underline cr-pointer"
                  :class="{ 'color-white': pattern == 2 }"
                  >อ่านรายละเอียด</a
                >
              </p>
            </div>
            <div class="box-inside-cookie button">
              <!-- <u class="text-font-14 pd-r-4 color-white">ตั้งค่าคุกกี้</u> -->
              <el-button
                @click="allowCookie"
                :type="pattern == 1 ? 'primary' : null"
                class="pd-x-1 mg-x-2"
                :class="{ 'color-primary': pattern == 2 }"
                >ตกลง</el-button
              >
            </div>
          </div>
        </div>

        <el-dialog :visible.sync="dialogCookie" class="set-width-modal">
          <h2 class="color-black mg-t-less">การตั้งค่าคุกกี้</h2>
          <p class="text-justify color-black tab-text">
            คุกกี้ที่จำเป็นอย่างยิ่ง
            คุกกี้เหล่านี้จำเป็นสำหรับการทำงานของเว็บไซต์ของเราและไม่สามารถ
            ปิดในระบบของเราได้
            โดยปกติคุกกี้เหล่านี้จะถูกตั้งค่าเพื่อตอบสนองต่อสิ่งที่คุณกระทำเท่านั้น
            ซึ่งรวมถึง คำขอบริการ เช่น การตั้งค่าการกำหนดลักษณะความเป็นส่วนตัว
            การเข้าสู่ระบบ หรือการกรอกแบบ ฟอร์ม
            คุณสามารถตั้งค่าเบราว์เซอร์ให้บล็อกหรือแจ้งเตือนให้คุณทราบเกี่ยวกับคุกกี้เหล่านี้
            แต่หลังจาก นั้นบางส่วนของเว็บไซต์จะใช้งานไม่ได้
            คุกกี้เหล่านี้ไม่เก็บข้อมูลที่สามารถระบุตัวตนของคุณไว้
          </p>
          <div class="pd-t-2 is-flex ai-center js-between respon-flex">
            <div></div>
            <div class="is-flex ai-center respon-flex mt-20">
              <el-button @click="allowCookie" type="primary" class="pd-x-1"
                >ตกลง</el-button
              >
            </div>
          </div>
        </el-dialog>
      </div>
    </vue-cookie-accept-decline>
  </div>
</template>

<script>
import VueCookieAcceptDecline from "vue-cookie-accept-decline";

export default {
  components: {
    "vue-cookie-accept-decline": VueCookieAcceptDecline,
  },
  props: {
    pattern: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      dialogCookie: false,
    };
  },
  mounted() {
    // console.log('pattern',this.pattern)
    // this.$refs.cookie.removeCookie();
  },
  methods: {
    allowCookie() {
      this.$refs.cookie.accept();
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-text {
  text-indent: 80px;
}
.custom-cookie {
  display: none;
  .cookie-box {
    position: fixed;

    bottom: 0;
    width: 100%;
    z-index: 999;
    .change-display {
      padding: 20px 15vw;
      opacity: auto;
      .box-inside-cookie {
        p {
          word-break: break-word;
        }
      }
    }
    @media (max-width: 768px) {
      .change-display {
        display: block;
        .box-inside-cookie.button {
          text-align: right;
          button {
            margin: 0 !important;
          }
        }
      }
    }

    .text-underline {
      text-decoration: underline;
    }
    .text-underline:hover {
      color: #353535;
    }
  }
}
@media (max-width: 575.98px) {
  .respon-flex {
    display: block;
  }
  .mt-20 {
    margin-top: 20px;
  }
}
@media (max-width: 400px) {
  .mt-20 .el-button {
    display: block;
    width: 100%;
  }
  .el-button + .el-button {
    margin-left: 0;
    margin-top: 10px;
  }
}
</style>
